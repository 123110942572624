// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
  
// Step 2: Define your component
const BuyPage = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/buy.jpg" alt="Get Into Investing! Header" layout="constrained" />

          <table align="center" width="90%">
            <tr>
              <td>
              <div><br />
                <p>
                  <center>
                    <h2>
                      Quickly learn how to analyze investments for the long and short term, using the power of the smartphone in your pocket.
                    </h2>
                  </center></p><br />

                <p><h3><i>Get Into Investing!</i> teaches readers:</h3>
                  <table>
                    <tr>
                      <td> <div>
                      <div align="center">
                        <span><b>Risk</b></span>
                        </div>
                      </div>
                      </td>
                        <td>  
                          <div>
                            <div>
                              <br/>Manage risk by knowing the returns, stability of the company and what you have available to invest. <br/><br/>
                            </div>
                          </div>
                        </td>
                    </tr>
                    <tr>
                      <td>  
                        <div>
                          <div align="center">
                            <b>Safeguard Savings</b>
                          </div>
                        </div>
                      </td>
                      <td>  
                        <div>
                          <div>
                            <br/>Safeguard through protected investments that provide a steady return, while knowing how much capital you are comfortable with investing in higher return prospects. <br/><br/>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>  
                        <div>
                          <div align="center">
                            <b>Financial Security</b>
                          </div>
                        </div>
                      </td>
                        <td>  
                          <div>
                            <div>
                              Depending on if you want to save for a nice vacation or financial security, <i>Get Into Investing!</i> can show you how to achieve independence over time.<br/><br/>
                            </div>
                          </div>
                        </td>
                    </tr>
                  </table>
                </p>
              </div>
              </td>  
            </tr>
            <tr>
              <td>
                <center>
                <p>
                It is important to note that there is minimal math involved. You will not ever at any point have to pull out a pen and paper, along with a calculator, to figure out your returns. The book teaches simple ways to look at investments that will keep you in tune with your returns and let the trading company's website figure all the math for you.<br />
                <br /><h2>Click here for a link to the book on Amazon.</h2><br />
                </p>
                </center>                
              </td>
            </tr>
          </table>

          <table width="136px" align="center">
          <tr>  
          <td>  
          <center>
            <p>
            <Link to="https://www.amazon.com/kindle-dbs/entity/author/B0917VTD2P?_encoding=UTF8&node=283155&offset=0&pageSize=12&searchAlias=stripbooks&sort=author-sidecar-rank&page=1&langFilter=default#formatSelectorHeader" >
            <div style={{
              height:`218px`, 
              align:`top-center`, 
              backgroundImage: `url("gii.jpg")`, 
              position: `center`, 
              size: `cover`
              }}>
            </div>
          </Link><br /><br />
          </p>
          </center>
          </td>  
            </tr>

          </table>

        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default BuyPage